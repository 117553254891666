import { ConvenienceFeeGetResponse, ConvenienceFeeType, CustomFeeResponse } from '@/Model/payments/types';
import { formatCurrency } from '../common/formatters';

export function percentToNumber(value: string) {
  return Number(`${value}`.replace('%', ''));
}

export function getFormattedFeeDisplayValue(fee: ConvenienceFeeGetResponse | CustomFeeResponse): string {
  let result = fee.feeValue.toLocaleString(); // Default catch-all in case the fee.feeType value is somehow not one of the ConvenienceFeeType enum members
  if (fee.feeType === ConvenienceFeeType.FixedAmount) {
    result = formatCurrency(fee.feeValue);
  }
  else if (fee.feeType === ConvenienceFeeType.Percentage) {
    result = `${fee.feeValue}%`;
  }
  return result;
}