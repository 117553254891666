export enum FormValidationError {
    REQUIRED,
    MIN,
    MAX,
    MAX_LENGTH,
    TYPE,
    DUPLICATE,
    FORMAT
}

export interface FormValidation {
    changes: boolean;
    dirty: boolean;
    error: FormValidationError | null;
    errorMessage: string;
    valid: boolean;
}

export type FormFieldValidation<T> = {
    [Property in keyof T]: FormValidation
};

export enum VeeValidateProviderMode {
    Aggressive = 'aggressive',
    Passive = 'passive',
    Lazy = 'lazy',
    Eager = 'eager'
}