import APP_CONST from '@/constants/AppConst';
import { CurrencyInputOptions } from 'vue-currency-input';


export function formatCurrency(amount: number) {
  const currencyOptions: CurrencyInputOptions = APP_CONST.CURRENCY_OPTIONS;
  const currencyFormatter = new Intl.NumberFormat(
    currencyOptions.locale,
    {
      style: 'currency',
      currency: (typeof currencyOptions.currency === 'string') ? currencyOptions.currency : 'USD'
    }
  );
  return currencyFormatter.format(amount);
}